@import '../../assets/styles/variables.scss';

.page404 {
  .title {
    font-size: 2em;
  }

  .txt {
    color: gray;
    font-size: .9em;
  }

  .btn {
    width: 100%;
    background-color: transparent;
    border: solid 1px $brand-color;
    transition: ease .4s;

    &:hover {
      background-color: $brand-color;
      border: solid 1px $brand-color;

      a {
        color: white;
      }
    }

    a {
      color: $brand-color;
    }
  }
}