.terms-privacy {
  padding: 5em 0 4em;
  max-height: 70vh;

  .subtitle {
    font-weight: bold;
    font-size: 1.2em;
  }

  p {
    font-size: .9em;
  }
}

