.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
        min-height: auto;
    }

    .modalContainer{
        position: relative;
        top: 10rem;
        background-color: #ffffff;
        padding: 1rem;
        width: 55%;
        max-width: 1480px;
        padding: 0;
        border-radius: .8em;
        overflow: hidden;

        @media screen and (max-width:414px) {
            top: 5vh;
            width: 90%;
        }

        .body {
            padding: 2em 1.2em;
            padding-right: 0;
            overflow-y: scroll;
            max-height: 45vh;

            @media screen and (max-width:414px) {
                max-height: 45vh;
                padding-right: 2em;
            }

            .title {
                font-size: 2em;
            }

            .conditions {
                font-size: .8em;
            }
        }

    }

    .btnClose {
        position: absolute;
        right: -3px;
        top: -2%;
        z-index: 99999;

        color: #1E7EC6;
        font-size: 1.6em;
        transition: ease .6s;

        @media screen and (max-width:414px) {
            top: 1%;
            right: 0;
        }

        &:hover {
            color: crimson
        }
    }
}

.no-pr {
    padding-right: 0 !important;
}