@import '../../assets/styles/variables.scss';

.logo {
  max-width: 15%;
  padding-left: 0%;
  padding-top: 0%;

  @media screen and (max-width:414px) {
    max-width: 80px;
  }
}

.navbar-nav .nav-item {
  padding: .5em .5em;

  a {
    color: $brand-color;
    font-weight: $regular;
    transition: ease 0.4s;
    font-size: 1.3em !important;

    &:hover {
      color:$brand-color-hover;
    }

    @media screen and (max-width: 414px) {
      color: white;
    }
  }

  @media screen and (max-width: 414px) {
    background: rgba(24, 157, 239, 0.75);
    color: white;
    text-align: center;
    margin: 0.2em 0;
    border-radius: 4px;
  }
}



.navbar-light .navbar-toggler {
  border-color: transparent;
}
