#promotions {
    padding-bottom: 8vh;

    .header{
        margin-top: 10vh;
        margin-bottom: 4vh;

        .title {
            font-size: 3.9em;
            padding-left: 1%;
        }
    }
}

@media (max-width: 414px) {
    #promotions {
        padding-bottom: 8vh;
        .header{
            margin-top: 10vh;
            margin-bottom: 4vh;
            .title {
                font-size: 2.9em;
            }
        }
    }
}

