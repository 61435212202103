@import url('../../assets/styles/variables.scss');

// html {
//     min-height: 100%;
//     position: relative;
//   }
//   body {
//     margin: 0;
//     margin-bottom: 40px;
//   }
.floating{
    position: fixed;
    bottom: 4%;
    right: 0%;
    z-index: 99999;
}



.assistant  {
    background-image:url(../../assets/images/assistantIcon.png);
    background-position:center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 8vh;
    height: 8vh;
    padding: .2em;
}

.assistant-modal {
    display: none;
    position: absolute;
    bottom: 100%;
    right: -25%;
    opacity: 0;
    transition: ease-in-out -6s;
}

.assistant-modal.show {
    display: block;
    opacity: 1;
}

.assistant:focus {
    outline: 0;
    box-shadow: none;
}


.fondo{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;

}

.ordenaAqui {
    position: absolute;
    width: 100%;
    min-width: 160px;
    bottom: -0.9vh;
    right: 4vw;
    text-align: center;
    padding: .4em;
    background: #1E7EC6;
    border-radius: .4em;
    color: white;

    @media screen and (max-width:414px) {
        bottom: -0.6vh;
        right: 14vw;
    }
}

.ordenaAqui.hide{
  display: none;
  opacity: 0;
}

.blue-square {
    width: 1.1em;
    height: 1.1em;
    background: #1E7EC6;
    position: absolute;
    bottom: 26%;
    right: -4%;
    transform: rotate(45deg);
}

df-messenger {
  --df-messenger-button-titlebar-color: #599EE2;
  --df-messenger-user-message: #1364a2;
  --df-messenger-font-color: white;
  --df-messenger-bot-message: #599EE2 ;

  div.chat-wrapper[opened="true"] {
    height: 70vh !important;
  }
}