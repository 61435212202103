@import './variables.scss';

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: $font;
}

.container {
  max-width: 80%;

  @media screen and (max-width:414px) {
    max-width: 100%;
  }
}

.content {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.vh80 {
  min-height: 73vh;
}

.content.inner {
  padding-top: 20vh;
}

.appgua-bg {
  background-image: url("../images/fondo.png");
  background-repeat: no-repeat;
  background-size: 100% 50%;
  background-attachment: fixed;
  background-position: bottom center !important;
}


.appgua-bg.bg-fixed-mobile.terms-privacy-bg {
  background-position: 0% 120% !important;
  padding: 0 !important;
}

.appgua-bg.big {
  background-size: 120% 70%;
}

.bg-fixed-mobile {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99999;
}

.gradient-white {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.80015756302521) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.80015756302521) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.80015756302521) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

  @media screen and (max-width:414px) {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.80015756302521) 90%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.80015756302521) 90%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.80015756302521) 90%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  }
}

.appgua-bg.bg-fixed-mobile.terms-privacy-bg {
  background-position: 0% 120% !important;
  padding: 0 !important;
}

.appgua-bg.big {
  background-size: 120% 70%;
}

.mockup {
  width: 100%;

  @media screen and (max-width:414px) {
    width: 140%;
    margin-left: -20%;
    margin-top: 4vh;
  }
}

// animations

.delay {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

//typograhpy

.title {
  color: $brand-color;
  font-size: 4em;
  font-weight: $bold;
  line-height: 1em;

  @media screen and (max-width:414px) {
    font-size: 4.1em;
    line-height: 1.05em;
    margin-top: 10%;
  }

  @media screen and (max-width:320px) {
    font-size: 2.5em;
  }
}

.subtitle {
  color: $brand-color-hover;
  font-size: 1.8em;
  line-height: 1em;
  font-weight: $regular;

  @media screen and (max-width:414px) {
    font-size: 1.6em;
    line-height: 1em;
    margin-top: 4%;
  }
}

.subtitle.txt-on-water {
  color: white !important;
  font-size: 1em !important;
  line-height: 1.4em;
  letter-spacing: 0.03em;
}

.txt {
  font-size: 1em;
  margin-bottom: 1em;

  p {
    margin-bottom: .5rem;
  }
}

@media screen and (max-width:414px) {
  .appgua-bg {
    background-size: 165%;
  }

  .appgua-bg.bg-fixed-mobile.terms-privacy-bg {
    background-position: 0% 100% !important;
    padding: 0 !important;
  }

  .appgua-bg {
    background-image: none; 
  }
  .appgua-bg:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: url("../images/fondo.png");
    background-repeat: no-repeat;
    background-size: 230%;
    background-position: bottom center !important;
  }

  .title-section {
    color: $brand-color;
    font-size: 2.8em !important;
    font-weight: $bold;
    line-height: 1.3em;
  }

  .description {
    margin-top: 8%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #3f7086;
    font-size: 1em;
    line-height: 1.3em;
    /* padding-bottom: 2%; */
  }
}
