.footer {
    &-floating {
        position: absolute;
        width: 100%;
        bottom: 2%;
    }

    ul {
        padding: 0;
    }
}


.legal{
    position: relative;
    left: 0;
    bottom: 0%;
    width: 100%;
    padding: 0% !important;
    text-align: center;

    a {
        padding: 1.2em !important;
        color: white;
        transition: ease .4s;
        &:hover {
            color: #1B4558;
            text-decoration: none;
        }
    }
}

.stor {
    max-width: 150px;
    vertical-align: middle;
    border-style: none;
    margin: 0%;

}

@media (min-width: 992px) {
    .legal{
        position: relative;
        left: 0;
        bottom: 10%;
        width: 100%;
        padding: 0;
        text-align: center;
        //padding-top: 50%;

        a {
            padding: 0 1em;
            color: white;
            transition: ease .4s;
            &:hover {
                color: #1B4558;
                text-decoration: none;
            }
        }
    }

    .stor {
        max-width: 150px;
        vertical-align: middle;
        border-style: none;
        margin: 0%;

    }
}