a:hover {
    text-decoration: none;
}
.Recomendaciones{
    color:#189DEF;
    font-size: 4.1em;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 1em;
}
.card {
    overflow: hidden;
    border: none;
    border-radius: .5em;
    -webkit-box-shadow: 3px 7px 12px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 3px 7px 12px 0px rgba(0,0,0,0.16);
    box-shadow: 3px 7px 12px 0px rgba(0,0,0,0.16);
    transition: ease .4s;
    // min-height: 50vh;

    &:hover {
        transform: scale(1.01);
    }

    .body{
        padding: 1.2em .8em;
    }

    .title {
        font-size: 1.5em;

        &:hover {
            text-decoration: none;
        }
    }
    .txt{
        font-size: .9em;
        color: #222;
        line-height: 1em;
    }
}

@media (max-width: 414px) {
    .Recomendaciones{
        font-size: 2.0em;
        color: #189DEF;
    }
    .card{
    
    .txt{
        font-size: 1.2em;
        color: #222;
        line-height: 1em;
    }
}

}