.cta-container {
    position: absolute;
    bottom: 7%;
    left: 0;
    min-width: 100%;

    .title {
        color: #fff;
        font-size: 1.2em;
        font-weight: bold;
    }
}