// Colors
$brand-color: #189DEF;
$brand-color-hover: #3f7086;
$black: #0f1d24;
$blue: #1E7EC6;
$green: #18CCBD;

//Fonts
$font: "Roboto", sans-serif;
$light: 300;
$regular: 400;
$bold: 700;